(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/widgets/expandable-article/assets/javascripts/expandable-article.js') >= 0) return;  svs.modules.push('/widgets/expandable-article/assets/javascripts/expandable-article.js');

'use strict';

class ExpandableArticle {
  constructor(options) {
    this.el = options.$el;
    this.collapsed = true;
    this.container = this.el[0];
    this.contentToggle = this.el[0].querySelector('.js-expandable-article-content-toggle');
    this.addEventListeners();
  }
  trackExpandClick() {
    const trackingName = this.container.dataset.track;
    if (trackingName) {
      svs.components.analytics.trackEvent({
        category: 'Articles',
        action: 'Read more',
        opt_label: trackingName
      });
    }
  }
  assembleTopHeaderHeight() {
    var _document$querySelect, _document$querySelect2, _document$querySelect3;
    const fixedTopContent = ((_document$querySelect = document.querySelector('.js-fixed-top-content')) === null || _document$querySelect === void 0 ? void 0 : _document$querySelect.offsetHeight) || 0;
    const itemsMenuPoker = ((_document$querySelect2 = document.querySelector('.items-menu-wrapper-poker-react')) === null || _document$querySelect2 === void 0 ? void 0 : _document$querySelect2.offsetHeight) || 0;
    const menuCasino = ((_document$querySelect3 = document.querySelector('.js-casino-main-navigation')) === null || _document$querySelect3 === void 0 ? void 0 : _document$querySelect3.offsetHeight) || 0;
    return fixedTopContent + itemsMenuPoker + menuCasino;
  }
  toggleHandler() {
    if (this.collapsed) {
      this.contentToggle.textContent = 'Läs mindre';
    } else {
      window.scrollTo({
        behavior: 'smooth',
        top: this.container.offsetTop - this.assembleTopHeaderHeight()
      });
      this.contentToggle.textContent = 'Läs mer';
    }
    this.container.classList.toggle('expandable-article-collapsed');
    this.collapsed = !this.collapsed;
  }
  addEventListeners() {
    this.contentToggle.addEventListener('click', () => this.toggleHandler());
    this.contentToggle.addEventListener('click', () => this.trackExpandClick(), {
      once: true
    });
  }
  removeListeners() {
    this.contentToggle.removeListeners('click', () => this.toggleHandler(), this);
    this.contentToggle.removeEventListener('click', () => this.trackExpandClick(), {
      once: true
    });
  }
  destroy() {
    this.removeListeners();
  }
}
setGlobal('svs.widget.expandable_article.init', options => {
  if (options) {
    new ExpandableArticle(options);
  }
});

 })(window);