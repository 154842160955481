(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/widgets/expandable-article/views/index.js') >= 0) return;  svs.modules.push('/widgets/expandable-article/views/index.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_svs.widget=_svs.widget||{};
_svs.widget.expandable_article=_svs.widget.expandable_article||{};
_svs.widget.expandable_article.templates=_svs.widget.expandable_article.templates||{};
svs.widget.expandable_article.templates.index = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <div class=\"widget-default f-content js-expandable-article expandable-article expandable-article-collapsed"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isInverted") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":108},"end":{"line":2,"column":149}}})) != null ? stack1 : "")
    + "\" data-track=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"trackingName") || (depth0 != null ? lookupProperty(depth0,"trackingName") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"trackingName","hash":{},"data":data,"loc":{"start":{"line":2,"column":163},"end":{"line":2,"column":179}}}) : helper)))
    + "\">\n    <div>\n      <div class=\"expandable-article-article-container\">\n"
    + ((stack1 = lookupProperty(helpers,"with").call(alias1,(depth0 != null ? lookupProperty(depth0,"article") : depth0),{"name":"with","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":8},"end":{"line":7,"column":17}}})) != null ? stack1 : "")
    + "      </div>\n      <div class=\"expandable-article-content-toggle-container\">\n        <span class=\"expandable-article-content-toggle js-expandable-article-content-toggle\">Läs mer</span>\n      </div>\n    </div>\n  </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return " widget-inverted";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"components-content-simple_article-simple_article"),depth0,{"name":"components-content-simple_article-simple_article","data":data,"indent":"          ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"with").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"currentWidget") : depth0),{"name":"with","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":14,"column":9}}})) != null ? stack1 : "");
},"usePartial":true,"useData":true});
Handlebars.partials['widget-expandable_article-index'] = svs.widget.expandable_article.templates.index;
})(svs, Handlebars);


 })(window);